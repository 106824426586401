<template>
    <div
        class="delete-button"
        :style="getStyle(top, right, viewComparedStandardZoomRatio)"
        @click="handleDelete">
        <i class="el-icon-delete-solid"></i>
    </div>
</template>

<script>
export default {
    name: 'DeleteButton',
    components: {},
    props: {
        viewComparedStandardZoomRatio: {
            type: Number
        },
        comp: {
            type: Object
        },
        top: {
            type: Number,
            default: 16
        },
        right: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
        }
    },
    computed: {},
    watch: {},
    created() {
    },
    mounted() {},
    methods: {
        getStyle(top, right, viewComparedStandardZoomRatio) {
            return {
                'top': (top * viewComparedStandardZoomRatio) + 'px',
                'right': (right * viewComparedStandardZoomRatio) + 'px'
            }
        },
        handleDelete() {
            this.$eventDispatch('compDelete', this.comp)
        }
    }
}
</script>

<style lang="scss" scoped>
$coefficient: var(--coefficient, 1px);

.delete-button {
    position: absolute;
    background: #EEEEEE;
    width: calc(36 * #{$coefficient});
    height: calc(26 * #{$coefficient});
    border-radius: calc(15 * #{$coefficient});
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .el-icon-delete-solid {
        font-size: calc(20 * #{$coefficient});
        color: #919499;
    }
}
.delete-button:hover {
    background: #FCEDE8;

    .el-icon-delete-solid {
        color: #F26231;
    }
}
</style>
