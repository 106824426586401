<template>
    <div
        class="text-comp"
        :style="{
            'border-width': mode === 'edit' ? '1px' : '0px',
            'border-color': comp.color,
            '--coefficient': viewComparedStandardZoomRatio + 'px'
        }">
        <template v-if="mode === 'edit'">
            <div
                v-if="comp.value || inputShow"
                ref="entry"
                class="entry"
                :style="getStyle(comp)"
                :contenteditable="true"
                v-html="comp.value"
                @dblclick="handleEnter"
                @input="handleInput"
                @keydown="handleKeydown"
                @blur="handleBlur"
            ></div>
            <div
                v-else
                class="placeholder"
                :style="getStyle(comp)"
                @dblclick="handleEnter"
            >双击此处进行内容编辑</div>
            <!-- 删除按钮 -->
            <delete-button
                v-if="activatedItem === comp.id"
                :viewComparedStandardZoomRatio="viewComparedStandardZoomRatio"
                :comp="comp"
            ></delete-button>
        </template>
        <template v-else>
            <div
                ref="entry"
                class="entry"
                :style="getStyle(comp)"
                v-html="comp.value"
            ></div>
        </template>
    </div>
</template>

<script>
import DeleteButton from "./Sub/DeleteButton.vue"

export default {
    name: 'TextComp',
    components: {
        DeleteButton
    },
    props: {
        mode: {
            type: String
        },
        viewComparedStandardZoomRatio: {
            type: Number
        },
        activatedItem: {
            type: String,
            default: ""
        },
        comp: {
            type: Object
        }
    },
    data() {
        return {
            inputShow: false
        }
    },
    computed: {},
    watch: {},
    created() {
    },
    mounted() {},
    methods: {
        getStyle(comp) {
            return {
                fontFamily: comp.fontFamily,
                fontSize: comp.fontSize + 'px',
                color: comp.color
            }
        },
        handleEnter() {
            // this.$emit('enter')
            this.inputShow = true
            this.$nextTick(() => {
                // this.$refs.entry.focus()
                this.getSelectPos()
            });
        },
        getSelectPos() {
            // var esrc = document.getElementById(obj);
            // console.log("[getSelectPos]ref:", this.$refs.entry)
            var esrc = this.$refs.entry
            var range = document.createRange();
            range.selectNodeContents(esrc);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        },
        handleInput(e) {
            console.log("[handleInput]e:", e, "this.$refs.entry.innerHTML:", this.$refs.entry.innerHTML);
            // 注意：不能将 dataCopy 赋值给可编辑输入框绑定的值 data，否则重新赋值会使输入框的光标跑到最前面！！！
        },
        handleKeydown(e) {
            if (e.keyCode === 13) { // 回车
                document.execCommand('insertHTML', false, '<br>&zwnj;'); // 用<br>换行
                e.preventDefault();
            }
        },
        // 当光标失去焦点（也就是点击提交按钮的时候）给data赋值最后输入结果即可
        handleBlur(e) {
            console.log("[handleBlur]e.target.innerHTML:", e.target.innerHTML);
            this.comp.value = e.target.innerHTML;
        }
    }
}
</script>

<style lang="scss" scoped>
$coefficient: var(--coefficient, 1px);

.text-comp {
    position: relative;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-radius: 4px;
    padding: calc(14 * #{$coefficient}) calc(17 * #{$coefficient});
    box-sizing: border-box;
    overflow: hidden;
    cursor: move;

    .entry,
    .placeholder {
        width: 100%;
        height: 100%;
        line-height: 1.4;
        overflow: hidden;
        white-space: nowrap;

        div {
            word-break: break-all;
            word-wrap: break-word;
            white-space: pre-wrap;
        }
    }
    .entry:focus-visible {
        outline: none;
    }
}
</style>
