var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.mode === 'edit')?_c('vue-drag-resize',{key:_vm.comp.timestamp,staticClass:"comp-item",style:({
        opacity: _vm.comp.opacity,
        transform: `rotate(${_vm.comp.rotate}deg)`
    }),attrs:{"w":_vm.comp.width,"h":_vm.comp.height,"x":_vm.comp.left,"y":_vm.comp.top,"z":_vm.comp.zIndex,"minw":20,"minh":20,"aspectRatio":false,"preventActiveBehavior":true,"isActive":_vm.activatedItem === _vm.comp.id},on:{"clicked":_vm.onClicked,"resizing":_vm.onResizing,"dragging":_vm.onDragging}},[_c(_vm.compItem,{tag:"components",attrs:{"mode":_vm.mode,"viewComparedStandardZoomRatio":_vm.viewComparedStandardZoomRatio,"activatedItem":_vm.activatedItem,"comp":_vm.comp}})],1):_c('div',{staticClass:"comp-item",style:({
        position: 'absolute',
        width: _vm.comp.width + 'px',
        height: _vm.comp.height + 'px',
        top: _vm.comp.top + 'px',
        left: _vm.comp.left + 'px',
        opacity: _vm.comp.opacity,
        zIndex: _vm.comp.zIndex,
        transform: `rotate(${_vm.comp.rotate}deg) scale(${_vm.wheelZoomRatio}%)`
    })},[_c(_vm.compItem,{tag:"components",attrs:{"mode":_vm.mode,"viewComparedStandardZoomRatio":_vm.viewComparedStandardZoomRatio,"activatedItem":_vm.activatedItem,"comp":_vm.comp}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }