<template>
    <vue-drag-resize
        v-if="mode === 'edit'"
        :key="comp.timestamp"
        class="comp-item"
        :style="{
            opacity: comp.opacity,
            transform: `rotate(${comp.rotate}deg)`
        }"
        :w="comp.width"
        :h="comp.height"
        :x="comp.left"
        :y="comp.top"
        :z="comp.zIndex"
        :minw="20"
        :minh="20"
        :aspectRatio="false"
        :preventActiveBehavior="true"
        :isActive="activatedItem === comp.id"
        @clicked="onClicked"
        @resizing="onResizing"
        @dragging="onDragging">
        <components
            :is="compItem"
            :mode="mode"
            :viewComparedStandardZoomRatio="viewComparedStandardZoomRatio"
            :activatedItem="activatedItem"
            :comp="comp"
        ></components>
    </vue-drag-resize>
    <div
        v-else
        class="comp-item"
        :style="{
            position: 'absolute',
            width: comp.width + 'px',
            height: comp.height + 'px',
            top: comp.top + 'px',
            left: comp.left + 'px',
            opacity: comp.opacity,
            zIndex: comp.zIndex,
            transform: `rotate(${comp.rotate}deg) scale(${wheelZoomRatio}%)`
        }">
        <components
            :is="compItem"
            :mode="mode"
            :viewComparedStandardZoomRatio="viewComparedStandardZoomRatio"
            :activatedItem="activatedItem"
            :comp="comp"
        ></components>
    </div>
</template>

<script>
import VueDragResize from "vue-drag-resize";
import TextComp from "./TextComp.vue";
import PictureComp from "./PictureComp.vue";

export default {
    name: 'CompItem',
    components: {
        VueDragResize,
        TextComp,
        PictureComp
    },
    props: {
        mode: {
            type: String
        },
        viewComparedStandardZoomRatio: {
            type: Number
        },
        wheelZoomRatio: {
            type: Number
        },
        activatedItem: {
            type: String
        },
        comp: {
            type: Object
        }
    },
    data() {
        return {
        }
    },
    computed: {
        compItem() {
            return `${this.comp.type}Comp`
        }
    },
    watch: {},
    created() {
    },
    mounted() {},
    methods: {
        onClicked() {
            this.$eventDispatch('compClick', this.comp)
        },
        onResizing(e) {
            this.$eventDispatch('compResizing', {
                comp: this.comp,
                e: e
            });
        },
        onDragging(e) {
            this.$eventDispatch('compDragging', {
                comp: this.comp,
                e: e
            });
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
