<template>
    <div
        class="picture-comp"
        :style="{
            'border': mode === 'edit' ? '1px dashed #A3A3E0' : 'none',
            '--coefficient': viewComparedStandardZoomRatio + 'px'
        }">
        <img class="picture-comp-image" v-if="comp.value" :src="comp.value" alt="">
        <div v-else class="default-tips">
            <img class="picture-default-image"  :src="defaultImg" alt="">
            <span class="text">{{ defaultText }}</span>
        </div>
        <!-- 删除按钮 -->
        <delete-button
            v-if="mode === 'edit' && activatedItem === comp.id"
            :viewComparedStandardZoomRatio="viewComparedStandardZoomRatio"
            :comp="comp"
        ></delete-button>
    </div>
</template>

<script>
import DeleteButton from "./Sub/DeleteButton.vue"

export default {
    name: 'PictureComp',
    components: {
        DeleteButton
    },
    props: {
        mode: {
            type: String
        },
        viewComparedStandardZoomRatio: {
            type: Number
        },
        activatedItem: {
            type: String,
            default: ""
        },
        comp: {
            type: Object
        }
    },
    data() {
        return {
            defaultText: '请上传图片'
        }
    },
    computed: {
        defaultImg() {
            return require('@/assets/images/loginPageTemplate/default-img.png')
        }
    }
}
</script>

<style lang="scss" scoped>
.picture-comp {
    width: 100%;
    height: 100%;

    .picture-comp-image {
        width: 100%;
        height: 100%;
        object-fit: fill;
        display: inline-block;
    }
    .default-tips {
        background-color: rgba($color: #FFFFFF, $alpha: 0.8);
        box-sizing: border-box;
        cursor: move;
        height: 100%;
        padding: 13px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .picture-default-image {
            width: 46px;
            height: 46px;
        }
        .text {
            display: block;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 26px;
        }
    }
}
</style>
